.blog-container{
  height: max-content;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.541);
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 100vh;
}
.Load{
  color: wheat;
}
.featured-post-Container{
  margin: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}
.featured-post-Container.loaded {
  opacity: 1;
  transform: translateY(0);
}
.featured-post{
   background-color: #8087bd;
   border-radius: 15px;
   display: flex;
   flex-direction: row;
   padding: 10px;
   width: 70%;
}

.blog-header,.blog-header1{
  margin-top: 20px;
  margin-left: 14px;
  margin-bottom: 15px;
  font-size: 38px;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
 width: 70%;
 text-align: left;

}
.post-image-container{
  width: 50%;

}
.post-image{
  box-shadow: -8px -5px 5px rgba(0, 0, 0, 0.527);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  
}
h2{
  font-weight: bolder;
}
p{
  font-weight: 400;
}
.blogtitle{
  font-weight: bolder;
  font-size: 30px;
  color: black;
}
.post-text{
  
   margin: 30px;
   text-align: left;
     width: 50%;
}
.Rmbtn{
    margin-top: 20px;
    background-color: black;
    padding: 15px;
    color: white;
    border-radius: 10px;
    border: none;
}
.Rmbtn:hover{
  margin-top: 20px;
  background-color: #989cc2;
  padding: 15px;
  color: white;
  border-radius: 10px;
  border: none;
}
.more-articles{
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  padding: 50px;
}
.article-Rows{
  
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.article-Column1, 
  .article-Column2,
.article-Column3 {
  
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; /* Add space between items */ /* Add space between items */
  align-items: center;
}

/* Set the width of each article to one-third of the container width */

.article{
  box-shadow: -20px -20px 3px solid rgb(0, 0, 0);
  margin: 30px;
  margin-top: 30%;
  margin-bottom: 30%;
  border-radius: 20px;
   height: 100%;
   width: 100%;
}
.ImgBlog{

  object-fit: cover;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  margin-bottom: -15%;
 
}
.ArticleName{
  overflow-y: hidden;
   font-size: 28px;
}
.info{
   min-height: 40%;
   padding: 20px;
   padding-top: 30px;
   position: relative;
   background-color: #8087bd;
   border-radius: 40px 40px 20px 20px;
   height: max-content;
   margin-bottom: 0;
 
}
.RmLink{
  background-color: transparent;
  text-align: center;
  color: rgba(0, 0, 0, 0.562);
  text-decoration: underline 1px rgba(0, 0, 0, 0.596);
  border: 0px;
  font-size: 2.4vh;
  font-weight: bolder;
  margin-left: 0px;
}




.navbar-blog {
  border-bottom: 2px solid rgba(255, 255, 255, 0.267);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 2px  solid rgba(255, 255, 255, 0.288);
  background-color: rgba(0, 0, 0, 0.438);

}

.special{
  color: #8087bd !important;
}

.logo-blog {
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: 6vh;
  text-decoration: none !important;
  margin-left: 30px;



}

.brand {
  color: white;
  text-decoration: none !important; /* Remove underline */
 
}

.highlight {
  color: red;
  font-weight: bold;
  text-decoration: none; /* Remove underline */
}

.menu {
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  li {
    margin-right: 50px;
    cursor: pointer;
  }

  a {
    text-decoration: none; /* Remove underline */
    color: #ffffff; /* Set the link color */
  }

  a:hover {
    color: #8087bd; /* Set the link color on hover */
  }
}

.demo-btn-blog {
  background-color: #8087bd;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  margin: 25px;
}

@media screen and (max-width:1000px){
  .featured-post-Container{
    margin: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.5s ease, transform 1.5s ease;
  }
  .featured-post{
    background-color: #8087bd;
    border-radius: 15px;
    display: flex;
    flex-direction: column-reverse;
    width:  80%;
    height: max-content;
 }
 .post-text{
  margin: 10px;
  text-align: left;
    margin-bottom: 10px;
  width: 99%;
  height: max-content;

  

}
.post-image-container{
  width: 100%;
  margin-bottom: 10px;


}
.article-Rows{
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  justify-content: center;
  align-items: center;
}
.article-Column1, 
  .article-Column2,
.article-Column3 {
  
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; /* Add space between items */ /* Add space between items */
  align-items: center;
 
}

.blog-header{
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: -30px;
  font-size: 28px;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
 width: 100%;
 margin-left: 0px;

}
.blog-header1{
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}




.navbar-blog {
  width:100%;
  padding: 10px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}
.special{
  color: #8087bd !important;
}

.logo {
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: 6vh;
  text-decoration: none !important;
  margin: 0px;
}

.brand {
  color: white;
  text-decoration: none !important; /* Remove underline */
}

.highlight {
  color: red;
  font-weight: bold;
  text-decoration: none; /* Remove underline */
}

.menu-blog  {
  ul {
    list-style: none;
    display: right;
    margin-right: 0px;
    margin: 0;
    padding: 0;
  }

  li {
     display: flex;
     flex-direction: row;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none; /* Remove underline */
    color: #ffffff; /* Set the link color */
  }

  a:hover {
    color: #8087bd; /* Set the link color on hover */
  }
}

.demo-btn-blog{
  background-color: #8087bd;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
 
}
}