.Journey {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .Heading-J {
    font-size:  34px;
    font-weight: bold;
    color: white;
    margin-bottom:  40px;
  }
  
  .Content-J {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width:  100%;
  }
  
  .light-J {


  }
  

/* Active state with blue shadow for Steps  1 and  3 */
.Step-J.active.blue-shadow {
  box-shadow:  0  0  50px blue; /* Blue box shadow */
 
}

/* Active state with red shadow for Steps  2 and  4 */
.Step-J.active.red-shadow {
  box-shadow:  0  0  50px red; /* Red box shadow */
}
  
  .Steps-J {
    transition: box-shadow  0.5s ease-in-out; /* Smooth transition for the box shadow */
    display: flex;
    flex-direction: column;
    width:500px; /* Adjust as needed */
  }
  .StepOne:hover{
    
  }
  .StepOne, .StepTwo, .StepThree, .StepFour {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-bottom:  20px;
    background-color: rgba(0, 0, 0, 0.459);
    border-radius: 20px;
    text-align: left;
  }
    

  .StepOne, .StepThree{
    border: 2px solid blue;
  }
  .StepTwo, .StepFour{
    border: 2px solid red;
  }
  .Text-J {
    margin-right:  10px;
  }
  .FormButton-J{
       margin: 20px;
       width: max-content;
       background-color: transparent;
       border: 2px solid blue;
       color:white;
       padding: 20px;
       border-radius: 20px;
  }
  .FormButton-J:hover{
    margin: 20px;
     width: max-content;
     background-color: transparent;
     border: 2px solid blue;
     box-shadow: 2px 2px 2px  blue;
     color:white;
     padding: 20px;
     border-radius: 20px;
}
  .StepHeading {
    font-size:  21px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .StepImages-J{
      height: 200px;
      width: 200px;
      margin: 20px;
      border-radius: 20px;
  }
  .StepImagesContainer-J{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

  }
  .TextDiff-J{
    text-align: center;
  }
  @media  screen and (max-width:1000px) {
    .Journey {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      padding-left: 20px;
      padding-right: 20px;
      padding: 40px;
      margin-bottom: -20px;
    }
    
    .Heading-J {
      font-size:  24px;
      font-weight: bold;
      color: white;
      margin-bottom:  40px;
    }
    
  }