.VMP-Container{
   overflow-x: hidden;
   align-items: center;
   height: max-content;
   min-height: 100vh;
   background-repeat: no-repeat;
   background-size: contain;
   justify-content: center;
  align-content: center;

}

.VMP-ExceptNav{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.VMP-Header{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-top: 25px;
  font-size: 30px;
  font-weight: bolder;
  color: #ffffff;
}
.VMP-dropdown_container{
  display: flex;
  flex-direction: row;
  margin: 40px;
  padding: 20px;
  background: linear-gradient(to bottom, #2b2b2b, #131313);
 
  border-radius: 10px;
}

.VMP-Choose{
  color: white;
  font-size: 20px;
  margin: 10px;
}

.VMP-dropdown-div{
  border-radius: 10px;
  padding: 10px;
  background-color: #8087bd;
  font-size: 15px;
}
.VMP-Name{

  

  font-size: 20px;
  color: white;
}
.VMP-WD{
  
}

.VM-Projects-Collection {
  justify-content: center;
  align-content: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3%;
  


  
}


.VM-project-item {
  margin: 15px;
  color: white;
  width: 23%;
  margin-bottom: 40px;
  position: relative;
}

.VM-Project-Card {
  background-color: rgba(0, 0, 0, 0.644);
  border: 2px solid rgba(255, 255, 255, 0.473);
  height:  max-content;
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: right;
  align-items: left;
}

.VM-logo-Projects {

  color: black;
  
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-left: 25px;
   margin-right: 25px;
   margin-top: 25px;
   height: max-content;

   border-radius: 20px;
   margin-bottom: 10%;
  

}
.VM-img{
  
  width: 100%;
 
  object-fit: cover;
  
}

.VM-visit {
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.295);
  width: 25%;
  color: rgba(255, 255, 255, 0.568);
  border-radius: 25px;
  font-size: 14px;
  margin-top: 10%;
  margin-bottom: 0;
  padding: 10px;
  

}
.VM-visit:hover {
  background-color: transparent;
  border: 2px solid rgb(0, 0, 0);
  width: 25%;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  font-size: 14px;
  margin-top: 10%;
  margin-bottom: 0;
  padding: 10px;
  

}
.VM-visit-Container{
  width: 100%;
  display: flex;
  align-content: right;
  justify-content: right;
   text-align: right;
}

p {

  font-size: 18px;
  margin-bottom: 0px;

}

.Trap{
  border-radius: 10px;
  padding: 20px;
  border: none;
  font-weight: bold;
  background-color: #8087bd;
  font-size: 15px;
  margin: 50px;
  

}
.Trap:hover{
  border-radius: 10px;
  padding: 20px;

  font-weight: bold;
  background-color: #8087bd00;
  color: wheat;
  border: 2px solid wheat;
  font-size: 15px;
  margin: 50px;
  

}











.logo {
    display: flex;
    align-items: center;
    font-weight: bolder;
    font-size: 6vh;
    text-decoration: none !important;
  }
  
  .brand {
    color: white;
    text-decoration: none !important; /* Remove underline */
  }
  
  .highlight {
    color: red;
    font-weight: bold;
    text-decoration: none; /* Remove underline */
  }
  
  .menu {
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
    }
  
    li {
      margin-right: 50px;
      cursor: pointer;
    }
  
    a {
      text-decoration: none; /* Remove underline */
      color: #ffffff; /* Set the link color */
    }
  
    a:hover {
      color: #8087bd; /* Set the link color on hover */
    }
  }
  .h1{
    
  }
  
  .demo-btnd {
    background-color: #8087bd;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 20px;
  }
  @media screen and (max-width: 1000px){
    .VM-Projects-Collection {
      justify-content: center;
      align-content: center;
      justify-content: space-evenly;
      display: flex;
      flex-direction: column;

      margin: 0%;
      
    
    
      
    }
    .demo-btnd {
      background-color: #8087bd;
      color: white;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      border-radius: 20px;
    }
    .VM-project-item {
      margin: 15px;
      color: white;
      width: 300px;
      margin-bottom: 40px;
      position: relative;
    }
    .navbar{
     
    }
    .demo-btnd{
      display: flex;
      background-color: #8087bd;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 20px;
      
    } 
  }