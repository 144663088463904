/* Contact.css */
.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    overflow: hidden;
}
.contact-container {
    
    background-color: #8087bd;
    width: 100%;
    padding: 50px;
    border-radius: 0;
    transition: width 2s ease, border-radius 1s ease;
    opacity: 0;
    transform: translateY(50px);
  }
  
  .contact-container.loaded {
    opacity: 1;
    transform: translateY(0);
    width: 60%;
    border-radius: 30px;
  }
  
  .contact-content {
    display: flex;
  }
  
  .left-section {
    width: 60%;
    color: white;
    text-align: left;
  }
  
  h2 {
    font-size: 28px;
  }
  
  .bold-text {
    font-weight: bolder;
    font-size: 30px;

    font-family: Arial, Helvetica, sans-serif ;
  }
   .line{
     font-size: 15px;
     margin-top: 10px;
     margin-bottom: 50px;
  }
  
  .right-section {
    width: 40%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .whatsapp-btn{
      border-radius: 30px;
      border: 2px solid black;
  }
  .BtnText{
      margin-right: 20px;
      margin: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  label {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .input {
    padding: 10px;
    text-align: center;
    border: 2px solid black;
    margin-bottom: 20px;
    width: 70%;
    background: transparent;
    border-radius: 30px;
    height: 25px;
    color: rgb(0, 0, 0);

  }
  
  .submit-btn {
     height: 100%;
    background-color: black;
    color: white;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    border: 2px solid black;
   width: 70%;

    border-radius: 60px;
  }
  .submit-btn:hover{
    height: 100%;
   background-color: black;
   color: white;
   padding: 8px;
   cursor: pointer;
   text-align: center;
   border: 2px solid black;
  width: 60%;

   border-radius: 60px;
 }
  input::placeholder {
    color: rgb(0, 0, 0); /* Change the color to your desired color */
  }
  .whatsapp-btn {
    background-color: black;
    color: white;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .whatsapp-btn img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  /* Add this to your Contact.css file */

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.644);
  z-index: 1000;
}
.FS{
  display: none;
}
.popup-content {
  background: #8087bd;
  border: 3px groove rgb(255, 255, 255);
  padding:  20px ;
  border-radius: 10px;
  max-width: 400px;
  
  display: flex;
  flex-direction: column;
}
p{
  margin-top: 0;
}
.close {

  margin-left:95%;
  margin-top: -20px;
  
  cursor: pointer;
  font-size: 30px;
  color: rgb(255, 0, 0);
}

.wabtn {
  margin-top: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (max-width:1000px) {
  .contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    overflow: hidden;
}
.contact-content {
  display: flex;
  flex-direction: column;
}
.right-section {
   margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -20px;

}
.left-section {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}
.contact-container.loaded {
  opacity: 1;
  transform: translateY(0);
  width: 70%;
  border-radius: 20px;
  padding: 40px;
}

}