.App {
  text-align: center;
 background-size: 100%;
 background-image: url("./imgs/bg5.jpg");
 overflow-x: hidden;
 height: max-content;

 
}
body {

}
.Loading{
  height: 100vh;
  background-color: black;
  color: red;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 25px;
}
.LoadingLogo{
  height: 500px;
  margin-bottom: 0px;
  
}

@media screen and (max-width:1000px) {
  .App {
    text-align: center;
   background: center;
   background-size: 190%;
   background-image: url("./imgs/bg5.jpg");
   overflow-x: hidden;
   height: max-content;

  
  
   
  }
}