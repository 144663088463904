/* Contact.css */
.d-contact{
    display: flex;
    flex-direction: column;

    overflow: hidden;
    height: 100vh;
}
.d-contact-container {
    display: flex;

    justify-content: center;
    align-content: center;
    background-color: #8087bd;
    width: 100%;
    padding: 50px;
    border-radius: 0;
    transition: width 2s ease, border-radius 1s ease;
    opacity: 0;
    transform: translateY(50px);
  }
  
  .d-contact-container.loaded {
    opacity: 1;
    transform: translateY(0);
    width: 60%;
    border-radius: 30px;
  }
  .dcenter{
    height:100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .d-contact-content {
    display: flex;
  }
  
  .d-left-section {
    width: 60%;
    color: white;
    text-align: left;
  }
  
  h2 {
    font-size: 28px;
  }
  
  .d-bold-text {
    font-weight: bolder;
    font-size: 30px;

    font-family: Arial, Helvetica, sans-serif ;
  }
   .d-line{
     font-size: 15px;
     margin-top: 10px;
     margin-bottom: 50px;
  }
  
  .d-right-section {
    width: 40%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .d-whatsapp-btn{
      border-radius: 30px;
      border: 2px solid black;
  }
  .d-BtnText{
      margin-right: 20px;
      margin: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  label {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .d-input {
    padding: 10px;
    text-align: center;
    border: 2px solid black;
    margin-bottom: 20px;
    width: 70%;
    background: transparent;
    border-radius: 30px;
    height: 25px;
    color: rgb(0, 0, 0);

  }
  
  .d-submit-btn {
     height: 100%;
    background-color: black;
    color: white;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    border: 2px solid black;
   width: 70%;

    border-radius: 60px;
  }
  input::placeholder {
    color: rgb(0, 0, 0); /* Change the color to your desired color */
  }
  .d-whatsapp-btn {
    background-color: black;
    color: white;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .d-whatsapp-btn img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  /* Add this to your Contact.css file */

.d-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.644);
  z-index: 1000;
}

.d-popup-content {
  background: #8087bd;
  border: 3px groove rgb(255, 255, 255);
  padding:  20px ;
  border-radius: 10px;
  max-width: 400px;
  
  display: flex;
  flex-direction: column;
}
p{
  margin-top: 0;
}
.d-close {

  margin-left:95%;
  margin-top: -20px;
  
  cursor: pointer;
  font-size: 30px;
  color: rgb(255, 0, 0);
}

.d-wabtn {
  margin-top: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.navd{
  overflow-y: hidden;
    background-color: rgba(0, 0, 0, 0.596);
    height: max-content;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 60px;
    overflow-x: hidden;
    top: 0;
    z-index: 100;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    border-bottom: 1px solid rgba(255, 255, 255, 0.329);
    border-top: 0px;

}
.logod {
    display: flex;
    align-items: center;
    font-weight: bolder;
    font-size: 6vh;
    text-decoration: none !important;
  }
  
  .brand {
    color: white;
    text-decoration: none !important; /* Remove underline */
  }
  
  .highlight {
    color: red;
    font-weight: bold;
    text-decoration: none; /* Remove underline */
  }
  
  .menud {
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
    }
  
    li {
      margin-right: 50px;
      cursor: pointer;
    }
  
    a {
      text-decoration: none; /* Remove underline */
      color: #ffffff; /* Set the link color */
    }
  
    a:hover {
      color: #8087bd; /* Set the link color on hover */
    }
  }
  
  .demo-btn {
    background-color: #8087bd;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 20px;
  }
  @media screen and (max-width:1000px) {
    .d-contact{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 40px;
      overflow: hidden;
  }
  .d-contact-content {
    display: flex;
    flex-direction: column;
  }
  .d-right-section {
     margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -20px;
  
  }
  .d-left-section {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  .d-contact-container.loaded {
    opacity: 1;
    transform: translateY(0);
    width: 70%;
    border-radius: 20px;
    padding: 40px;
  }



  
  

  .navd{
  
    width: 100%;
    background-color: rgba(0, 0, 0, 0.596);
    height: max-content;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-left: none;
    overflow-x: hidden;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    border-bottom: 1px solid rgba(255, 255, 255, 0.329);
    border-top: 0px;

}
.logod {
display: none;

 
  }
  
  .brand {
    color: white;
    text-decoration: none !important; /* Remove underline */
  }
  
  .highlight {
    color: red;
    font-weight: bold;
    text-decoration: none; /* Remove underline */
  }
  

  
  .demo-btn {
    background-color: #8087bd;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 20px;
  }


  }
  