.UpdateContainer{
    display: flex;
    flex-direction: row;
    background-color: rgba(212, 75, 75, 0.568);
    color: white;
    height: max-content;
    width: 100%;
     justify-content: center;
     align-items: center;
     padding: 10px;
    text-align: center;
}
.Update-Text{
    color: white;
    font-size: 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.Update-Btn{
    color: white;
    border: 0px;
    text-decoration: 2px white underline;
    font-size: 15px;
    background-color: transparent;
}
@media screen and (max-width:1000px){
    .Update-Text{
        color: white;
        font-size: 13px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        margin-top: 3px;
    }
    .Update-Btn{
        color: white;
        border: 0px;
        text-decoration: 2px white underline;
        font-size: 14px;
        background-color: transparent;
    }
}