/* Main.css */

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 58vh;
}
  
.demo-call-btn {
    background: transparent;
    color: white;
    padding: 12px 30px;
    cursor: pointer;
    border-radius: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    border: 2px solid rgba(255, 255, 255, 0.459);
    border-bottom: 2px solid rgba(255, 255, 255, 0.205);
 }
  .demo-call-btn:hover {
      transition: 2s;
    background: #8087bd;
    color: white;
    padding: 12px 30px;
    border: none;
    cursor: pointer;
    border-radius: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    border: 2px solid #8087bd;
  }
  
  .call-logo {
    margin-left: 5px;
    height: 22px;
    
    
  }
  
  .text-container {
    text-align: center;
 
    color: white;
  }
  
  .h3 {
    margin: 0;
    font-size: 70px;
    color: white;
    margin-bottom: 10px;

  }
  h1{
    margin: 0;
    font-size: 70px;
    color: white;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column; /* Change to column to stack the spans vertically */
    overflow: hidden;
    color: white;
  }
  
/* Main.css */

/* ... (existing styles) */
/* Main.css */

/* ... (existing styles) */

/* Main.css */

/* ... (existing styles) */
.Text{
    display: flex;
    flex-direction: row;
    justify-content: center;

    
}
.p{
   font-size: 20px;

   
  

}
.text-animation {
    width: 150px; /* Adjust the width as needed */
    overflow: hidden;
  
    text-align: left;
    margin-left:10px ;
    
  }
  
.text-animation span {

    opacity: 0;
    transform: translateY(50px);
    animation: revealText 0.8s forwards ease-in-out;
  }
  
  @keyframes revealText {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .slide-up {
    animation: slideUp 0.8s ease-in-out forwards;
    margin-top: 10px;
  }
  
  .slide-down {
    animation: slideDown 0.8s ease-in-out forwards;
    margin-top: 10px;
  }
  
  @keyframes slideUp {
    to {
      transform: translateY(50px);
      opacity: 0;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .text-animation span {
    margin-right: 10px;
  }
  
  @media screen and (max-width: 800px){
    .main-container{
    padding-top: 20px;
    height: 40vh;
    padding-bottom: 0px !important;

    }
    .demo-call-btn {
      background: transparent;
      color: white;
      padding: 13px 25px ;
      cursor: pointer;
      border-radius: 40px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      border: 2px solid rgba(255, 255, 255, 0.459);
      border-bottom: 2px solid rgba(255, 255, 255, 0.205);
      margin-bottom: 10px;
   }
    .demo-call-btn:hover {
        transition: 2s;
      background: #8087bd;
      color: white;
      padding: 13px 25px ;
      border: none;
      cursor: pointer;
      border-radius: 40px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      border: 2px solid #8087bd;
      margin-bottom: 10px;
    }

    .h3 {
      margin: 0;
      font-size: 48px;
       color: white;
  
    }

    
    .Text{
      display: flex;
      flex-direction: row;
      justify-content: center;
     text-align: center;
     align-content: center;
    }
    .p{
      font-size: 11px !important;
      font-weight: bolder;
      
    }
    .text{
      font-size: 11px;
    }
    .text-animation{
      width: 50px;
    }
}