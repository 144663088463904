/* Services.css */

.ServicesContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  opacity: 0; /* Set initial opacity to 0 */
  transform: translateX(-200px); /* Move the container up initially */
  transition: opacity 0.5s ease, transform 1.5s ease; /* Add transition properties */
  margin-bottom: 100px;
  transform: translateX(100%); /* Initial state: move the container right initially */
  
}

.ServicesContainer.loaded {
  opacity: 1;
  transform: translateX(0); /* Final state: move the container to its original position */
 
  
}

.Laptop {
  max-width: 60%;
  max-height: 60%;
  rotate: 6deg;
  transform-origin: center;
  transition: transform 0.5s ease;
  margin-bottom: 0;
}


.ServiceOverlay {
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.836);
  padding: 4px;
  rotate: 6deg;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  transition: transform 0.5s ease, left 0.3s ease;
  padding: 15px;
  position: absolute;
  left: -100%;
  transition: left 1s ease-out;
 }
 
 .ServicesContainer.loaded .ServiceOverlay {
   left: 30%;
 }

.ServiceRows {
  text-align: left;
  margin-right: 20px;
  cursor: pointer;
}

.ServiceHeading {
  font-size: 24px;
  margin-bottom: 20px;
}

.ServiceImage {
  height: 400px;
  width: 300px;
  object-fit: contain;
  height: auto;
  margin-left: auto;
  box-shadow: -4px -4px 4px rgba(255, 255, 255, 0.589);
}

.ServiceRow {
  margin-bottom: 10px;
  background-color: rgba(128, 128, 128, 0.295);
  font-size: 20px;
  padding: 4px;
}

.ServiceRow:hover {
  margin-bottom: 10px;
  background-color: rgb(128, 128, 128);
  font-size: 20px;
  padding: 4px;
}

.ServicesContainer.rotate .Laptop {
  transform: rotate(-6deg);
}

.ServicesContainer.rotate .ServiceOverlay {
  transform: translate(-50%, -50%) rotate(-6deg);
  left: 50%;
}
@media screen and (max-width: 1300px){
  .ServiceOverlay {
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.836);
    padding: 4px;
    rotate: 6deg;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    transition: transform 0.5s ease, left 0.3s ease;
    padding: 15px;
  }
  
  .ServiceRows {
    text-align: left;
    margin-right: 10px;
  }
  
  .ServiceHeading {
 
    font-size: 20px;
    margin-bottom: 10px;
  }
  .ServiceRow {
    margin-bottom: 10px;
    background-color: rgba(128, 128, 128, 0.295);
    font-size: 10px;
    padding: 4px;
  }
  
  .ServiceRow:hover {
    margin-bottom: 10px;
    background-color: rgb(128, 128, 128);
    font-size: 10px;
    padding: 4px;
  }
  .ServiceImage {
    height: 300px;
    width: 200px;
    object-fit: contain;
    height: auto;
    margin-left: auto;
    box-shadow: -4px -4px 4px rgba(255, 255, 255, 0.589);
  }
  
}
@media screen and (max-width: 1000px){
    .ServicesContainer{
      display: none;
    }
}