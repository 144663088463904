.showcase-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  overflow: hidden;
  margin-top: 0%;
  margin-bottom: 40px;
  margin-top: 0px;
 }
 
 .showcase-item {
  margin-top: 0;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
 }
 
 .showcase-item.L {
  transform: translateX(-100%);
 }
 
 .showcase-item.R {
  transform: translateX(100%);
 }
 
 .showcase-container.loaded .showcase-item {
  opacity: 1;
  transform: translateX(0);
  padding: 60px;
  padding-top: 0;
 }
 
 .showcase-item img {
  height: 60px;
 }
 
 .showcase-item p {
  color: white;
  font-size: 3vh;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: bolder;
  margin: 0;
  padding-top: 5px;
 }
 
 @media screen and (max-width:1000px) {
  .showcase-container.loaded .showcase-item {
     opacity: 1;
     transform: translateY(0);
     padding: 0px;
     padding-top: 0;
  }
  .showcase-container {
     display: flex;
     justify-content: center;
     margin-left: 50px;
     margin-right: 50px;
     align-content: center;
     justify-content: space-around;
  }
  .showcase-item img {
     height: 30px;
  }
  .showcase-item {
     margin: 20px;
  }
  .showcase-item p {
     color: white;
     font-size: 1.5vh;
     font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
     font-weight: bolder;
     margin: 0;
     padding-top: 5px;
  }
 }
 