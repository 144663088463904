/* Footer.css */

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    background: rgba(255, 255, 255, 0.103); /* Transparent background with some blur effect */
    margin-top: -80px;
  }
  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
  }
  
  .footer-column {
      margin-top: 20px;
    max-width: 200px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.527); /* Adjust the text color */
  }
  
  h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.5); /* Adjust the text color */
  }
  