/* Team.css */
.LeadingTeam-Mobile{
  margin-bottom: 20px;
}
.slick-list {
  margin:  0 -10px; /* Adjust this value to create space around the slides */
}

.slick-slide > div {
  padding:  0  10px; /* Adjust this value to create space between the slides */
}
.slick-active {
  /* Your custom styles for the active center slide go here */
  z-index:  10; /* Higher z-index to bring the active slide to the front */
  transform: scale(1.03); /* Slightly scale up the active slide */
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 30px;
}

@media (max-width: 1000px) {
  .team-member-Labourers {
     flex: 0 0 100%;
     
  }
  

 }
 .LoadingText{
   font-size: 30px;
   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
 } 
 @media (min-width: 1001px) {
  .team-member-Labourers {
     flex: 0 0 calc(100% / 3);
  }
 }
 .SecondDiv{
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   margin-bottom:20px;
   margin-top:20px;
 
 }
.team-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;
  margin: 40px 60px -80px;
  background-color: rgba(128, 128, 128, 0.267);
  border: rgba(255, 255, 255, 0.425);
  border-radius: 40px;
}

.h2 {
  margin-top: 20px;
  font-size: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 550;
  margin-bottom: 20px;
}

.quote {
  font-size: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bolder;
  margin: 20px 0;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 70px;
}

.team-members {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.LeadingTeam {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin-bottom: 40px;
}

.team-member{
  width: 25%;
  margin: 10px;
   
}

.TeamImageReviewContainer {
  border-radius: 30px;
  border: 2px solid rgba(194, 194, 194, 0.753);
  padding: 0;
  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.TeamReview {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(133, 133, 133, 0.774);
  color: rgb(255, 255, 255);
  padding: 20px;
}
.Teamlinks{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  justify-content: space-evenly;
}
.linkImg{
  height: 30px;
}
.linkImg_L{
  height: 30px;
  width: 30px !important;
}
.TeamRoastHeading{
  font-size: 20px;
  margin-bottom: 10px;
  text-decoration: underline 2px solid white;
}

.team-member:hover .TeamReview {
  display: block;
 
}
.team-member-Labourers:hover  .TeamReview {
  display: block;
}
.team-member:hover  .TeamImageContainer {
  
}
.lead-img {
    border-radius: 30px;
    height: 300px;
    width: 100%;
    margin: 0px;
    margin-bottom: 0;
}

.team-member-Labourers {
  margin: 0px ;
}

.Labourers {
margin-bottom: 50px;
width: 350px;

}
.Labourers-Container{
   overflow: visible;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px;
}
.slide {
  width: 100%;
  height: 400px;
  transition: all 0.5s ease;
 }
 
.slideshow-container {
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  
}
.TeamImageReviewContainer-L{
  border-radius: 30px;
  border: 2px solid rgba(194, 194, 194, 0.753);
  padding: 0;

  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
 
.p {
    color: rgba(255, 255, 255, 0.712);
    font-size: 16px;
    margin-top: 10px;
}
.cursor {
  display: inline-block;
  vertical-align: middle;
  width: 0.1em;
  background-color: white;
  animation: blink 1s infinite;
}
.video-container{
  width: 100%;
  margin-top: 0px !important;
  padding: 0px;
}
.youtube-video-placeholder{
  border-radius: 20px ;
  width: 500px ;
  height: 280px;
  border: 2px solid grey;
  display: flex;
  justify-content:center;
  align-items: center;
  object-fit: contain;
  margin-bottom: 80px;
}

@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}



@media screen and (max-width: 1000px){
  .Labourers {
    margin-bottom: 50px;
    width: 100%;
    height: 100%;
    overflow: visible;
    
    }
    .Labourers-Container{
       overflow: visible;
       width: 100%;
       height: 100%;

       display: flex;
       align-items: center;
       justify-content: center;
       padding: 0px;
    }

  .SecondDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
  
  }
.team-container {
  text-align: center;
  padding: 5px;
  color: white;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: -80px;
  background-color: rgba(128, 128, 128, 0.267);
  border: rgba(255, 255, 255, 0.425);
  border-radius: 10px;
}
.h2 {
  font-size: 26px;
  margin-bottom: 15px;
}
.quote{
  font-size: 15px;
  width: 100%;
  overflow: visible;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 400;
  margin: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0px solid white;
  margin-bottom: 40px;
}
.LeadingTeam{
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  justify-content: space-evenly;
  width: 100%;
}

.team-member{
  
  width: max-content;
  margin: 10px;
  position: relative;
}
.video-container{
  justify-content: center;
  display: flex;
  align-items: center;
}
.youtube-video-placeholder{
  width: 300px;
  height: 169px;
 
}

.team-member:hover .TeamReview{
  display: block;
  width: 100%;
  padding: 0px;
  transform-origin: none;
}
.team-member-Labourers:hover .TeamReview{
  display: block;
  width: 100%;
  padding: 0%;
  transform-origin: none;
}
.TeamImageReviewContainer {
  border-radius: 30px;
  border: 2px solid rgba(194, 194, 194, 0.753);
  padding: 0;
  height: 300px;
  width: 300px;
  overflow: hidden;
  position: relative;
}


.team-member:hover  .TeamImageContainer {

}
}


