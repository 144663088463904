.bpw-floating-button {
    /* Aligns the button to the right side of the container */
    float: right;
    /* Sets the fill color of the button icon */
    fill: #000b7d;
    /* Changes the cursor to a pointer when hovering over the button */
    cursor: pointer;
    /* Removes the outline around the button when clicked */
    outline: none;
 
    /* Centers the icon inside the button element */
    display: flex;
    align-items: center;
    justify-content: center;
 
    /* Sets the z-index to ensure the button is on top of other elements */
    z-index: 1;
    /* Sets the width and height of the button */
    width: 52px;
    height: 52px;
    /* Changes the cursor to a pointer when hovering over the button */
    cursor: pointer;
    /* Rounds the corners of the button */
    border-radius: 50%;
    /* Adds a shadow effect to the button */
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    /* Clips any content that overflows the button */
    overflow: hidden;
 
    /* Removes padding and borders from the button */
    padding: 0;
    border: none;
    background-clip: padding-box;
 
    /* Adds a transition effect to the button when its size changes */
    transition: width 1s, height 1s;
}