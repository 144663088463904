/* Navbar.css */

.navbar {
    background-color: rgba(0, 0, 0, 0.596);
    height: max-content;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 60px;
   
    top: 0;
    z-index: 100;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    transform: translateY(-100%); /* Initially, move the navbar up by its own height */
    transition: transform 0.5s ease-in-out; /* Add this line for the transition effect */
    border-bottom: 1px solid rgba(255, 255, 255, 0.329);
    border-top: 0px;
  }
    
  a {
    text-decoration: none; /* Remove underline */
 
  }

  .navbar.show {
    transform: translateY(0); /* Move the navbar down to its normal position */
  }

  .logo {
    display: flex;
    align-items: center;
    font-weight: bolder;
    font-size: 6vh;
    text-decoration: none !important;
  }

  .brand {
    color: white;
    text-decoration: none !important; /* Remove underline */
  }

  .highlight {
    color: red;
    font-weight: bold;
    text-decoration: none; /* Remove underline */
  }

  .menu {
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
    }
  
    li {
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      margin-right: 50px;
      cursor: pointer;
      font-size: large;
    }
  
    a {
      text-decoration: none; /* Remove underline */
      color: #ffffff; /* Set the link color */
    }
  
    a:hover {
      color: #8087bd; /* Set the link color on hover */
    }
  }

  .demo-btn {
    background-color: #8087bd;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 20px;
  }
  .Dropdown-icon {
    display: none;
    cursor: pointer;
  }
  
  .dropdown-menu {
    list-style: none;
    background-color: rgba(0, 0, 0, 0.644);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
  }
  
  .dropdown-menu li {
    margin: 10px 0;

  }
  .dropdown-menu li :hover {

    margin: 10px 0;
    color: #8087bd;

  }
  .Dropdown-Icon-img{
    height: 10px;
    border: 4px solid red;
    right: 0px;
  }
  .None{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  @media screen and (max-width: 1000px) {
    .navbar{
      padding: 20px ;
      display: flex;
      justify-content: space-between !important;
    }
    .menu {
      display: none;
    }
    .demo-btn {
      display: none;
    }
    .logo{
      margin-right: 25%;
    }
    .Dropdown-icon {
      display: flex;
      
      height: 30px !important;
   
    }
    .cross ,.dd{
      display: flex;
      flex-direction: flex-end;
    }
  
    .navbar.show {
      transform: translateY(0);
    }
  
    .dropdown-menu {
      display: block;
       color: white !important;
      background-color: rgba(0, 0, 0, 0.966);
      display: flex;
      flex-direction: column;
       margin-top: 0px;

      
    }
    li{
 border-bottom: 1px solid rgba(128, 128, 128, 0.288);
 
      padding-bottom: 10px;
      padding-top: 5px;
   
     
    width: 100%;

      text-decoration: none !important;
    }
    
   
    
  }
  .None{
    background-color: transparent;
    border :none;
    color: white;
    font-size: large;
  }
  .None:hover{
    background-color: transparent;
    border :none;
    color: #8087bd;
  }