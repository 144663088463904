.ReadMoreContainer{
    background-image: url(./imgs/bg5.jpg);
    height: max-content;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
   
}

.load{
  color: white;
  min-height: 100vh;
}
.RM-Blogs{
  background-color: #000000;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 70px;
  margin-bottom: 20px;
  border-radius: 40px;
  height: max-content;
   border: 3px solid #8087bd;
}
.Trap-Blog-Container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}
.title-RMBlogs{
  text-align: left !important;
  padding: 0px 30px 30px 30px;
  font-size: 50px;
  color: #8087bd;
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.ImageAndTextBox-RMBlogs{
  padding:0px 30px 30px;
  text-align: left;
  margin-top: 0px;
  display: flex;
  flex-direction: column-reverse;
}
.Content-RMBlogs{
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: larger;
  color: #8087bd !important;
  overflow: hidden;
  object-fit: contain;
}
.Content{
  color: #8087bd !important;
}
.ImageConatiner-RMBlogs{
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  object-fit:contain;
  margin-top:20px;
  margin-bottom:20px;
  
}
.Image-RMBlogs{
  height: 100%;
  border-radius: 30px;
}
.Trap-Blog{
  margin-top: 20px;
  margin-bottom: 20px;
   padding: 20px 30px ;
   background-color: #8087bd;
   color: #000000;
   border-radius: 20px;
   border: none;
   font-size: 15px;
}
.Trap-Blog:hover{
  margin-top: 20px;
  padding: 18px 20px ;
  background-color: #8087bd;
  color: black;
  border-radius: 20px;

  font-size: 15px;
}
.TagsContainer{
  padding: 30px;
  color: rgb(0, 0, 0);
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}
.Tag{
  background-color: #8087bd;
  padding: 10px 15px;
  border-radius: 20px;
}
.logo-blog {
    display: flex;
    align-items: center;
    font-weight: bolder;
    font-size: 6vh;
    text-decoration: none !important;
  }
  
  .brand {
    color: white;
    text-decoration: none !important; /* Remove underline */
  }
  
  .highlight {
    color: red;
    font-weight: bold;
    text-decoration: none; /* Remove underline */
  }
  

  .demo-btn-blog {
    background-color: #8087bd;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 20px;
  }
  
@media screen and (max-width:1000px){


  .ReadMoreContainer{
    background-image: url(./imgs/bg5.jpg);
    height: max-content;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.RM-Blogs{
  background-color: black;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 20px;
  height: max-content;

}
.Trap-Blog-Container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}
.title-RMBlogs{
  text-align: left !important;
  padding: 0px 20px 20px;
  font-size: 30px;
  color: #8087bd;
}
.ImageAndTextBox-RMBlogs{
  padding:0px 20px 20px;
  text-align: left;
  margin-top: 0px;
}
.ImageConatiner-RMBlogs{
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  object-fit:contain;
  margin-top:20px;
  margin-bottom:20px;
  
}
.Image-RMBlogs{
  height: 100%;
  width: 100%;
  border-radius: 30px;
}
.Content-RMBlogs{

 color: #8087bd !important;
}
.Trap-Blog{
  margin-top: 20px;
  margin-bottom: 20px;
   padding: 20px 30px ;
   background-color: #8087bd;
   color: black;
   border-radius: 20px;
   border: none;
   font-size: 15px;
}
.Trap-Blog:hover{
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 18px 20px ;
  background-color: #8087bd;
  color: black;
  border-radius: 20px;
  border: 2px solid black;
  font-size: 15px;
}


}