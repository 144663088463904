.ServicePhoneContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}

.PhoneImage {
  position: relative;
  
}
.weoffer{
  margin-bottom: 15px;
  margin-top: 15px;
  
}
.sligingHeadline{
  font-size: 15px;
  font-weight: 300;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-top: 30px;
}

.SlidingServices {
  position: absolute;

  top:  50%;
  left:  50%;
  transform: translate(-50%, -30%);
  width:  80%; /* Ensure it takes the full width of the parent */
  height:  100%; /* Ensure it takes the full height of the parent */

}

.ServiceItem {

  color: white;
  border:  3px solid rgba(128, 128, 128, 0.658); /* Grey border */
  background-color: rgba(0, 0, 0, 0.685);
  border-radius:  10px; /* Border radius */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.slidingImage {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width:  90%; /* Adjust as needed */
  height: 100px; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the entire area without stretching */
  margin: auto; /* Centers the image within its container */
}

.slick-dots li button {
  color: white;
  width:  10px; /* Width of the dot */
  height:  10px; /* Height of the dot */
  background-color: rgb(255, 255, 255); /* Color of the dot */
  border-radius:  50%; /* Make the dot circular */
  font-size:  0; /* Hide the numbers */
  border:  0; /* Remove border */
}

/* Style for the active dot */
.slick-dots li.slick-active button {
  background-color: #808BD7; /* Color of the active dot */
}
/* Ensure images align in the center of the carousel */
.slick-track {
  display: flex;
  align-items: center;
}

/* Ensure the slider takes the full height of the parent */
.slick-list {
  height:  100%;
}

/* Optional: Style for the dots */
.slick-dots li button:before {
  color: grey;
}

.slick-dots li.slick-active button:before {
  color: #000;
}

@media  screen and (min-width:1000px){
    .ServicePhoneContainer{
        display: none;
    }
}