/* CookieBanner.css */
.cookie-banner {
    /* ...other styles... */
  }
  
  .cookie-banner button {
    /* ...other styles... */
  }
  
  .cookie-banner button:hover {
    background-color: #6a70a3;
  }
  