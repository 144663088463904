/* Ensure that the slides have a background color */
.slick-slide {
  
  position: relative; /* Position the slides relative to each other */
  z-index:  1; /* Default z-index for non-active slides */
}

/* Dim non-active slides */
.slick-slide:not(.slick-active) {

  opacity:  0.5; /* Adjust the opacity value as needed */
  z-index:  0; /* Lower z-index for non-active slides */
}

/* Ensure that the active slide is fully opaque and above the dimmed slides */
.slick-active {
  opacity:  1;
  z-index:  2; /* Higher z-index for the active slide */
}

/* Add margin to the next arrow */
.slick-next {

  margin-right:  10px; /* Adjust the value as needed */
  background: transparent;
}

/* Add margin to the previous arrow */
.slick-prev {
  margin-left:  10px; /* Adjust the value as needed */
}

.LoadingText{
  font-size: 30px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: rgb(255, 255, 255);
}
.projects-container {
  text-align: center;
   padding: 20px;
   margin-bottom: 50px;
  color: white;
}

h2 {
  color: white;
  font-size: 28px;
  margin-bottom: 50px;
}

.Projects-Collection {
  margin: 20px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  opacity: 0; /* Set initial opacity to 0 */
  transform: translateY(50px); /* Move the container down initially */
  transition: opacity 1.5s ease, transform 1.5s ease; /* Add transition properties */
}

.loaded .Projects-Collection {
  opacity: 1;
  transform: translateY(0);
}

.project-item {
  width: 100%;

  position: relative;
}

.Project-Card {
 margin: 10px;
  background-color: rgba(0, 0, 0, 0.644);
  border: 2px solid rgba(255, 255, 255, 0.473);
  border-left: 6px solid rgba(255, 255, 255, 0.473);
  border-bottom: 6px solid rgba(255, 255, 255, 0.473);
  height: 300px;
  border-radius: 20px;
  border-bottom-left-radius: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.logo-Projects {

  color: black;
  height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding-left: 10%;
   padding-right: 10%;
   padding-top:10%;
   padding-bottom: 10%;
   overflow: hidden;
   border-radius: 20px;
  
}
.img{

  border-radius: 10px;
 
}

.visit {

  background-color: rgba(255, 255, 255, 0.473);
  color: white;
  width: 30%;
  text-align: center;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 60px;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  right: 0px;
  bottom: 0;
}



.visit:hover {

  background-color: transparent;
  border: 2px solid gray;
  color: white;
  width: 30%;
  text-align: center;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 60px;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  right: 0;
  bottom: 0;
}

p {
  font-size: 18px;
  margin-bottom: 0px;

}

.view-more-button {
  border: 2px solid rgba(255, 255, 255, 0.295);
  text-align: center;
  color: rgba(255, 255, 255, 0.568);
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.295);
  margin-top: 50px;

}

.view-more-button:hover {
  border: 2px solid rgba(255, 255, 255, 0.918);
  text-align: center;
  color: rgba(255, 255, 255, 0.918);
 
}
@media screen and (max-width :800px){


  .img{
 
    object-fit: contain;
    
    height: 300px;
    border-radius: 20px !important;
  }
  .project-item {
    width: 100%;
    position: relative;
  }
  
.logo-Projects {
 
  color: black;
  height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding-left: 20px;
   padding-right: 20px;
   padding-top: 20px;
   padding-bottom: 20px;
   overflow: hidden;
   border-radius: 20px;
}
.visit {
margin-top: 0px;

}
}


